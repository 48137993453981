import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './FooterComponent.css'; // Local css file for FooterComponent

function FooterComponent() {
  return (
    <footer className="footer">
      <div className="footer__line"></div>
      <div className="footer__content">
        <div className="footer__section">
          <p>© 2024. All Rights Reserved</p>
        </div>
        <div className="footer__section">
          <p>Designed by Teknoozu</p>
        </div>
        <div className="footer__section footer__icons">
          <a href="https://www.facebook.com/profile.php?id=61562843517752&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://x.com/teknoozu" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://www.linkedin.com/company/104690308/admin/feed/posts/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;
