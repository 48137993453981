import React from 'react';
import MainComponent from './Components/MainComponent';


function App() {
  return (
    <div className="App">
     <MainComponent/>
    </div>
  );
}

export default App;
